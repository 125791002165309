<template>
  <div class="mobile">
    <title-bar></title-bar>
    <about></about>
    <feature></feature>
    <contact></contact>
    <about-footer></about-footer>
    <back></back>
  </div>
</template>

<script>
import TitleBar from "./Mobile/TitleBar.vue";
import About from "./Mobile/About.vue";
import Feature from "./Mobile/Feature.vue";

export default {
  name: "MobileAbout",
  components: {
    TitleBar,
    About,
    Feature,
    Contact: () => import('./Mobile/Contact.vue'),
    AboutFooter: () => import('./Mobile/AboutFooter.vue'),
    Back: () => import('./Mobile/Back.vue'),
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mobile {
    width: 100%;
}
</style>
