<template>
  <div>
    <div class="container">
      <div class="sub-head">{{ title }}</div>
      <div class="sub-des">{{ des }}</div>
      <div class="content">
        <div class="item" v-for="(item, id) in featureList" :key="id">
          <img :src="item.iconSrc" class="item-icon" />
          <div class="item-title">{{ item.title }}</div>
        </div>
        <div class="img"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feature",
  data() {
    return {
      title: "Our Features",
      des: "Try Share Karo, the share app, it is 300 times faster than Bluetooth",
      bg: require("@/assets/img/about_02.png"),
      featureList: [
        {
          title: "File transfer without size limit",
          iconSrc: require("@/assets/icon/icon_files_M.png"),
        },
        {
          title: "Share files without internet",
          iconSrc: require("@/assets/icon/icon_offline_M.png"),
        },
        {
          title: "Easy to use, very helpful for sharing",
          iconSrc: require("@/assets/icon/icon_lightning.png"),
        },
        {
          title: "Strong file management",
          iconSrc: require("@/assets/icon/icon_management.png"),
        },
        {
          title: "Transfer all types of files",
          iconSrc: require("@/assets/icon/icon_files.png"),
        },
        {
          title: "Transfer files in rocket speed",
          iconSrc: require("@/assets/icon/icon_rocket.png"),
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  //height: rem(1300px);
  background: #ffffff;
  .sub-head {
    font-size: rem(40px);
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #2f9cf6;
    line-height: rem(66px);
    text-align: center;
    margin-top: rem(80px);
  }
  .sub-des {
    font-size: rem(28px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #757575;
    line-height: rem(32px);
    text-align: center;
    padding: rem(12px 36px 10px);
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      width: rem(320px);
      height: rem(100px);
      background: #f1f7ff;
      border-radius: rem(8px);
      margin: rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(22px) 0 rem(22px) rem(22px);
      font-size: rem(24px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      .item-icon {
        width: rem(44px);
        height: rem(44px);
        margin-right: rem(22px);
        background-size: 100% 100%;
      }
    }
  }
  .img {
      width: rem(440px);
      background: url(../../assets/img/about_02.png) no-repeat;
      height: rem(763px);
      background-size: 100% 100%;
  }
}
</style>
