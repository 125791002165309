<template>
  <div class="container">
    <div
      class="nav-bar"
      :class="{ white: scrollTop !== 0, shadow: showShadow }"
    >
      <div class="img"><img src="@/assets/img/logo.png" /></div>
      <div class="tab">
        <ul>
          <li>
            <router-link to="/PC/Home"
              >Home
              <div :class="{ light: $route.name === 'PCHome' }"></div
            ></router-link>
          </li>
          <li>
            <router-link to="/PC/About"
              >About
              <div :class="{ light: $route.name === 'PCAbout' }"></div
            ></router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  data() {
    return {
      scrollTop: 0,
    };
  },
  computed: {
    showShadow() {
      return this.scrollTop !== 0;
    },
  },
  mounted() {
    this.scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";
.nav-bar {
  font-size: 0;
  width: 100%;
  height: 70px;
  //line-height: vw(70);
  //background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  .img {
    margin-left: 40px;
    //height: 70px;
    //text-align: center;
    img {
      width: 164px;
      height: 40px;
      background-size: 100% 100%;
      //margin: 0 auto;
    }
  }
  .tab ul {
    margin-right: 37px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    li {
      display: inline;
      margin: 0 37px;
      position: relative;

      a {
        font-family: PingFang;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        //height: 70px;
      }
      a.router-link-exact-active {
        color: #2f9cf6;
      }

      div {
        position: absolute;
        display: inline;
        width: 20px;
        height: 2px;
        bottom: -15px;
        left: 15px;
      }
    }

    li :hover div {
      background: #2f9cf6;
    }
    li:hover a {
      color: #2f9cf6;
    }
  }
}
.white {
  background: #ffffff;
}
.shadow {
  box-shadow: 0 1px rem(4px) 0 rgba(0, 0, 0, 0.1);
}
.light {
  background: #2f9cf6;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    .tab ul {
      li {
        margin: 0 18px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .nav-bar {
    .nav {
      li {
        margin: 0 26px;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .nav-bar {
    .nav {
      li {
        margin: 0 37px;
      }
    }
  }
}
@media screen and (min-width: 1601px) {
  .nav-bar {
    //font-size:0;
    //width: 100%;
    height: vw(70);
    //line-height: vw(70);
    .img {
      margin-left: vw(70);
      //height: 70px;
      //text-align: center;
      img {
        width: vw(164);
        height: vw(40);
      }
    }
    .tab ul {
      margin-right: vw(37);
      height: vw(70);
      line-height: vw(70);
      li {
        margin: 0 vw(37);
        a {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
