<template>
  <div>
    <div class="container">
        <div class="left"><div class="sub-head">{{ title }}</div>
      <div class="sub-des">{{ des }}</div></div>
      <div class="img">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      title: "About Us",
      des: "Share Karo is an India File Transfer App. You can use Share Karo to Share Files, Share Apps , Share Videos and Share All without internet. Share it with all your friends now! You can Share it for iPhone Free & Send Anywhere.",
      bg: require("@/assets/img/about_top_bg.png"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  height: vw(628);
  background: #f4f9ff;
  position: relative;
  padding-top: vw(70);
  .left {
      //width: 50%;
      display: block;
      padding: vw(124) vw(800) vw(194) vw(145);
  }
  .sub-head {
    width: vw(934);
    font-size: vw(64);
    font-family: HelveticaNeue-Bold, HelveticaNeue;
    font-weight: bold;
    color: #2f9cf6;
    line-height: vw(78);
  }
  .sub-des {
    width: vw(657);
    font-size: vw(24);
    font-family: HelveticaNeue;
    color: #666666;
    line-height: vw(36);
    margin-top: vw(20);
    // padding: 0 10px;
  }
  .img {
      position: absolute;
      bottom: 0;
      right: 0;
      background: url("../../assets/img/about_top_bg.png");
      width: vw(878);
      height: vw(531);
      background-size: 100% 100%;
  }
}
@media screen and (max-width: 1080px) {
.container {
  width: minPx(1600);
  height: minPx(628);
  padding-top: minPx(70);
  .left {
      padding: minPx(120) minPx(800) minPx(194) minPx(145);
  }
  .sub-head {
    width: minPx(934);
    font-size: minPx(64);
    line-height: minPx(78);
  }
  .sub-des {
    width: minPx(657);
    font-size: minPx(24);
    line-height: minPx(36);
    margin-top: minPx(20);
  }
  .img {
      width: minPx(878);
      height: minPx(531);
  }
}
}
@media screen and (min-width: 1601px) {
.container {
//   width: maxPx(1600);
  height: maxPx(628);
  .left {
      padding: maxPx(110) maxPx(800) maxPx(194) maxPx(200);
  }
  .sub-head {
    width: maxPx(934);
    font-size: maxPx(64);
    line-height: maxPx(78);
  }
  .sub-des {
    width: maxPx(657);
    font-size: maxPx(24);
    line-height: maxPx(36);
    margin-top: maxPx(20);
  }
  .img {
      width: maxPx(878);
      height: maxPx(531);
  }
}
}
</style>
