<template>
  <div class="container">
    <div
      class="nav-bar"
      :class="{ white: isNavShow || scrollTop !== 0, shadow: showShadow }"
    >
      <div><img class="logo" src="@/assets/img/logo.png" /></div>
      <div>
        <img
          class="icon"
          @click.prevent="toggleShowNav"
          :class="{ close: isNavShow }"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="isNavShow"
        class="nav"
        @click="hideNav"
        @touchmove.prevent.stop
        @mousewheel.prevent
      >
        <div class="menu">
          <div>
            <router-link
              to="/Mobile/Home"
              :class="{ active: $route.name === 'MobileHome' }"
              >Home<img src="@/assets/icon/icon_arrow.png" />
            </router-link>
          </div>
          <div>
            <router-link
              to="/Mobile/About"
              :class="{ active: $route.name === 'MobileAbout' }"
              >About<img src="@/assets/icon/icon_arrow.png"
            /></router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  data() {
    return {
      isNavShow: false,
      scrollTop: 0,
    };
  },
  computed: {
    showShadow() {
      return this.scrollTop !== 0;
    },
  },
  mounted() {
    this.scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    toggleShowNav() {
      this.isNavShow = !this.isNavShow;
    },
    hideNav() {
      this.isNavShow = false;
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" rel="stylesheet/scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  .nav-bar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: rem(110px);
    //background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
    transition: all 0.6s;
    .logo {
      margin: rem(25px 30px);
      width: rem(249px);
      height: rem(60px);
    }
    .icon {
      margin: rem(0 30px);
      width: rem(50px);
      height: rem(50px);
      content: url(../../assets/icon/icon_more.png);
    }
    .close {
      content: url(../../assets/icon/icon_delete.png);
    }
  }
  .nav {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: rem(110px);
    left: 0;
    z-index: 100;
    overflow: hidden;
    .menu {
      background: #ffffff;
      border-top: 1px solid #e0e1e3;
      div {
        //height: rem(98px);
        text-align: left;
        //margin-left: rem(54px);
        a {
          display: block;
          height: rem(98px);
          line-height: rem(98px);
          color: #666666;
          font-size: rem(32px);
          font-family: Helvetica-Neue;
          position: relative;
          margin-left: rem(56px);
          padding-right: rem(38px);
          border-bottom: 1px solid #e0e1e3;
        }
        a.router-link-exact-active {
          color: #2f9cf6;
          font-weight: 500;
        }
        img {
          width: rem(28px);
          height: rem(28px);
          //margin-right: rem(48px);
          //text-align: right;
          position: absolute;
          top: rem(35px);
          right: rem(38px);
        }
      }
      .active {
        color: #0279ff;
        font-family: Helvetica-Neue-Medium;
      }
    }
  }
}
.white {
  background: #ffffff;
}
.shadow {
  box-shadow: 0 1px rem(4px) 0 rgba(0, 0, 0, 0.1);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
  height: 100%;
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  height: 0;
  opacity: 0;
}
</style>
