<template>
  <div>
    <div class="container">
      <div class="sub-head">{{ title }}</div>
      <div class="sub-des">{{ des }}</div>
      <div class="content">
        <div class="left">
          <div class="item" v-for="(item, id) in featureList[0]" :key="id">
            <div class="icon-item">
              <div>{{ item }}</div>
            </div>
            <div class="icon-img">{{ id + 1 }}</div>
          </div>
        </div>
        <div class="img"><img :src="bg" /></div>
        <div class="right">
          <div class="item" v-for="(item, id) in featureList[1]" :key="id">
            <div class="icon-img">{{ id + 4 }}</div>
            <div class="icon-item">
              <div>{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feature",
  data() {
    return {
      title: "Our Features",
      des: "Try ShareKaro, the share app, it is 300 times faster than Bluetooth",
      bg: require("@/assets/img/about_02.png"),
      featureList: [
        [
          "File transfer without size limit",
          "Share files without internet",
          "Very easy to use, very helpful for sharing",
        ],
        [
          "Transfer all types of files",
          "Strong file management",
          "Transfer files in rocket speed",
        ],
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  height: vw(1064);
  background: #ffffff;
  .sub-head {
    font-size: vw(50);
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #2f9cf6;
    line-height: vw(61);
    text-align: center;
    margin-top: vw(120);
  }
  .sub-des {
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: vw(32);
    text-align: center;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
      width: vw(440);
      height: vw(763);
      img {
        width: vw(440);
        height: vw(763);
        background-size: 100% 100%;
      }
    }
    .item {
      display: flex;
      align-items: center;
      justify-items: center;
      margin: vw(25) vw(15);
      .icon-img {
        width: vw(24);
        height: vw(24);
        background: #4dabfe;
        border-radius: 50%;
        color: #ffffff;
        line-height: vw(24);
        text-align: center;
        font-size: vw(14);
      }
      .icon-item {
        width: vw(316);
        height: vw(100);
        background: #f1f7ff;
        border-radius: vw(8);
        margin: 0 vw(15);

        div {
          width: vw(261);
          font-size: vw(24);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #191919;
          margin: vw(17) vw(24);
        }
      }
    }
    .left {
      text-align: right;
    }
  }
}

@media screen and (max-width: 1080px) {
  .container {
    width: minPx(1600);
    height: minPx(1064);
    .sub-head {
      font-size: minPx(50);
      line-height: minPx(61);
      margin-top: minPx(120);
    }
    .sub-des {
      font-size: minPx(24);
      line-height: minPx(32);
    }
    .content {
      .img {
        width: minPx(440);
        height: minPx(763);
        img {
          width: minPx(440);
          height: minPx(763);
        }
      }
      .item {
        margin: minPx(25) minPx(15);
        .icon-img {
          width: minPx(24);
          height: minPx(24);
          line-height: minPx(24);
          font-size: minPx(14);
        }
        .icon-item {
          width: minPx(316);
          height: minPx(100);
          border-radius: minPx(8);
          margin: 0 minPx(15);

          div {
            width: minPx(261);
            font-size: minPx(24);
            margin: minPx(17) minPx(24);
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1601px) {
  .container {
    height: maxPx(1064);
    .sub-head {
      font-size: maxPx(50);
      line-height: maxPx(61);
      margin-top: maxPx(120);
    }
    .sub-des {
      font-size: maxPx(24);
      line-height: maxPx(32);
    }
    .content {
      .img {
        width: maxPx(440);
        height: maxPx(763);
        img {
          width: maxPx(440);
          height: maxPx(763);
        }
      }
      .item {
        margin: maxPx(25) maxPx(15);
        .icon-img {
          width: maxPx(24);
          height: maxPx(24);
          line-height: maxPx(24);
          font-size: maxPx(14);
        }
        .icon-item {
          width: maxPx(316);
          height: maxPx(100);
          border-radius: maxPx(8);
          margin: 0 maxPx(15);

          div {
            width: maxPx(261);
            font-size: maxPx(24);
            margin: maxPx(17) maxPx(24);
          }
        }
      }
    }
  }
}
</style>
