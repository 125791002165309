<template>
  <div>
    <title-bar></title-bar>
    <about></about>
    <feature></feature>
    <about-footer></about-footer>
  </div>
</template>

<script>
import TitleBar from "./PC/TitleBar.vue";
import About from "./PC/About.vue";
import Feature from "./PC/Feature.vue";

export default {
  name: "PCAbout",
  components: {
    TitleBar,
    About,
    Feature,
    AboutFooter: () => import("./PC/AboutFooter.vue"),
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
