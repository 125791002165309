<template>
    <div class="container">
      <div class="sub-head">{{ title }}</div>
      <div class="sub-des">{{ des }}</div>
      <div class="img"><img /></div>
    </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      title: "About Us",
      des: "Share Karo is an India File Transfer App. You can use Share Karo to Share Files, Share Apps , Share Videos and Share All without internet. Share it with all your friends now! You can Share it for iPhone Free & Send Anywhere.",
      bg: require("@/assets/img/about_top_bg.png"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  width: 100%;
  height: rem(684px);
  background: #f4f9ff;
  position: relative;
  padding: rem(130px 28px 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .sub-head {
    font-size: rem(40px);
    font-family: HelveticaNeue-Bold, HelveticaNeue;
    font-weight: bold;
    color: #2f9cf6;
    line-height: rem(66px);
  }
  .sub-des {
    //width: vw(657);
    font-size: rem(24px);
    font-family: HelveticaNeue;
    color: #666666;
    line-height: rem(33px);
    margin-top: rem(13px);
  }
  .img {
    width: rem(550px);
    height: rem(301px);
    position: absolute;
    bottom: 0;
    img {
      margin: 0 auto;
      content: url("../../assets/img/about_top_bg_M.png");
      width: 100%;
      
      background-size: 100% 100%;
    }
  }
}
</style>
