import { render, staticRenderFns } from "./PCAbout.vue?vue&type=template&id=efc23ae2&scoped=true&"
import script from "./PCAbout.vue?vue&type=script&lang=js&"
export * from "./PCAbout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efc23ae2",
  null
  
)

export default component.exports